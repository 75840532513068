import React from 'react'
import './App.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useParams
} from 'react-router-dom'
import { Header } from './Components/Header'
import { Text } from './Components/text'
import { PasswordResetForm } from './Components/forms'

function App(): JSX.Element {
  return (
    <Router>
      <div className='bg-grey-light px-3 py-2 min-h-screen'>
        <div className='bg-white p-3 max-w-lg m-auto min-h-lg h-full relative'>
          <div className='max-w-xs m-auto'>
            <Header></Header>
            <Switch>
              <Route path='/password/success'>
                <Text>Passwort wurde erfolgreich zurückgesetzt!</Text>
              </Route>
              <Route path='/password/reset/:email/:resetToken'>
                <Form />
              </Route>
              <Route path='/' exact>
                <Text>
                  Zum Zurücksetzen des Passworts folge bitte den Anweisungen in
                  der App.
                </Text>
              </Route>
              <Redirect to='/' />
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  )
}

const Form = () => {
  const { resetToken, email } =
    useParams<{ resetToken: string; email: string }>()
  console.log(resetToken, decodeURIComponent(email))
  return (
    <PasswordResetForm
      resetToken={resetToken}
      email={decodeURIComponent(email)}
    />
  )
}

export default App
