import React from 'react'
import * as Yup from 'yup'
import { withFormik, FormikProps, Form, Field } from 'formik'
import { Button } from '../button'
import axios from 'axios'
import { Text } from '../text'
import { Redirect } from 'react-router-dom'

// Shape of form values
interface FormValues {
  password: string
  password2: string
  resetToken: string
  email: string
}

interface PasswordFieldProps {
  placeholder: string
  name: string
}

const PasswordField = ({
  name,
  placeholder
}: PasswordFieldProps): JSX.Element => {
  return (
    <Field
      className='rounded-full px-5 py-4 mb-3 border border-grey-border w-full block font-light text-grey-dark focus:ring focus:ring-blue outline-none focus:ring-opacity-50'
      type='password'
      name={name}
      placeholder={placeholder}
      autoComplete='new-password'
    />
  )
}

const password_requirement =
  'Das Passwort muss aus min. 6 Zeichen bestehen und sollte einen Buchstaben und eine Zahl enthalten.'

const PasswordResetSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, password_requirement)
    .matches(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/, password_requirement)
    .required(password_requirement),
  password2: Yup.string()
    .required('Die Passwortbestätigung stimmt nicht über ein.')
    .oneOf(
      [Yup.ref('password'), null],
      ' Die Passwortbestätigung stimmt nicht über ein.'
    ),
  resetToken: Yup.string()
    .required('Fordern Sie erneut das Zurücksetzen des Passworts an.')
    .min(1, 'Fordern Sie erneut das Zurücksetzen des Passworts an.')
})

// Aside: You may see InjectedFormikProps<OtherProps, FormValues> instead of what comes below in older code.. InjectedFormikProps was artifact of when Formik only exported a HoC. It is also less flexible as it MUST wrap all props (it passes them through).
const InnerForm = (props: FormikProps<FormValues>) => {
  const { touched, errors, isSubmitting, isValid, status } = props
  if (status === 'success') {
    return <Redirect to='/password/success' />
  }
  if (status === 'error') {
    return <Text>Bitte probieren Sie es später nocheinmal</Text>
  }
  return (
    <Form>
      {!isValid && (
        <ol className='list-disc text-red font-light text-sm pl-4 py-6'>
          {errors.resetToken && <li>{errors.resetToken}</li>}
          {touched.password2 && errors.password2 && <li>{errors.password2}</li>}
          {touched.password && errors.password && <li>{errors.password}</li>}
        </ol>
      )}
      <div className='pb-8 pt-3'>
        <PasswordField name='password' placeholder='Neues Passwort' />
        <PasswordField
          name='password2'
          placeholder='Neues Passwort wiederholen'
        />
      </div>
      <Field type='text' name='email' autoComplete='username email' hidden />
      <Button type='submit' disabled={isSubmitting}>
        Submit
      </Button>
    </Form>
  )
}

// The type of props MyForm receives
interface MyFormProps {
  resetToken: string
  email: string
}
// Wrap our form with the withFormik HoC
export const PasswordResetForm = withFormik<MyFormProps, FormValues>({
  // Transform outer props into form values
  mapPropsToValues: (props) => {
    return {
      resetToken: props.resetToken || '',
      password: '',
      password2: '',
      email: props.email
    }
  },

  validationSchema: PasswordResetSchema,

  handleSubmit: async ({ password, password2, email, resetToken }, bag) => {
    const formData = new FormData()
    formData.append('password', password)
    formData.append('password_confirmation', password2)
    formData.append('email', email)
    formData.append('token', resetToken)
    bag.setStatus(null)
    bag.setSubmitting(true)

    try {
      const req = await axios.post(
        process.env.REACT_APP_RECOVERY as string,
        formData
      )
      bag.setSubmitting(false)
      if (req.status === 200) {
        return bag.setStatus('success')
      }
      bag.setStatus('error')
    } catch (e) {
      console.warn(e)
      bag.setStatus('error')
    }
  }
})(InnerForm)
