import React from 'react'
import ReactLogo from './KI_Matchmaking.png'
import './head.css'

export const Header = (): JSX.Element => {
  return (
    <div className='pb-9 sm:pb-4'>
      <img className='mx-auto mb-8 w-36' alt='KI Matchmaking' src={ReactLogo} />
      <h1 className='text-grey-dark text-2xl sm:text-xl mb-9 sm:mb-4 font-semibold'>
        Passwort zurücksetzen
      </h1>
      <div className='h-2 sm:h-1 w-full gradient rounded-full' />
    </div>
  )
}
