/* eslint-disable react/prop-types */
import React from 'react'
const style =
  'block  w-full text-center py-5 sm:py-3 text-white text-xl sm:text-base font-light rounded-full disabled:bg-opacity-30 disabled:cursor-not-allowed'

const primaryStyle = `${style} bg-blue`
const secondaryStyle = `${style} bg-grey-dark focus:ring focus:ring-blue outline-none focus:ring-opacity-50`
interface ButtonProps {
  onClick?: () => void
  disabled?: boolean
  primary?: boolean
  type?: 'button' | 'submit' | 'reset'
}
export const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  disabled,
  type,
  primary
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={primary ? primaryStyle : secondaryStyle}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

interface LinkProps {
  to: string
}

export const ButtonLink: React.FC<LinkProps> = ({ children, to }) => {
  return (
    <a className={primaryStyle} href={to}>
      {children}
    </a>
  )
}
